/* Global CSS Styles */
@font-face {
  font-family: "Product Sans";
  src: url("../assets/fonts/ProductSans-Black.woff") format("woff");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Product Sans";
  src: url("../assets/fonts/ProductSans-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Product Sans";
  src: url("../assets/fonts/ProductSans-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Product Sans";
  src: url("../assets/fonts/ProductSans-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Product Sans";
  src: url("../assets/fonts/ProductSans-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Product Sans";
  src: url("../assets/fonts/ProductSans-Thin.woff") format("woff");
  font-style: normal;
  font-weight: 200;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Product Sans", "Roboto", "Roboto", "Helvetica", "Arial",
    sans-serif !important;
}

// Styles for customizing MUI Datagrid
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows,
.MuiTablePagination-select {
  font-size: 0.75rem;
}

.MuiDataGrid-columnHeaderTitleContainer {
  padding: 0;
}

.MuiDataGrid-columnHeader > .MuiDataGrid-columnSeparator {
  display: none;
}

.MuiDataGrid-columnHeader {
  padding-left: 5px !important;
  padding-right: 0 !important;
}

// Hides hover options of data grid header
.MuiDataGrid-menuIcon {
  display: none !important;
}

// Custom datagrid to fix sorting icon
.DataGridSorting > div > div {
  flex-direction: row !important;
  justify-content: flex-end;
  padding: 0 !important;
}

// -------------------------------- //

// Styles for Toastify library
.Toastify__toast-body {
  justify-content: center;
}

.Toastify__toast-container {
  margin: 10px auto;
  width: 600px;
  align-items: center;
}

.Toastify__toast-icon {
  margin-right: 30px;
}

.Toastify__toast-body > div:last-child {
  display: contents;
}

@media screen and (max-width: 600px) {
  .Toastify__toast-container {
    width: 350px;
    margin-left: calc(50vw - 175px);
  }
}
